import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function PrivacyPage() {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <section className="md:p-0 text-left mt-28 md:mt-24">
        <div className="px-6 md:max-w-7xl mx-auto">
          <h1 className="inline-block mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
            Privacy Policy
          </h1>
          <div className="md:max-w-3xl">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              1. Introduction
            </h2>
            <p>
              Welcome to our privacy policy. We are Nubee (Pty) Ltd (Company
              Number X) and this is our plan of action when it comes to
              protecting your privacy. We respect your privacy and take the
              protection of personal information very seriously. The purpose of
              this policy is to describe the way that we collect, store, use,
              and protect information that can be associated with you or another
              specific natural or juristic person and can be used to identify
              you or that person (personal information).
            </p>
            <h3 className="font-bold pt-5">1.1 Audience</h3>
            <p>
              This policy applies to you if you are:
              <ul className="list-disc ml-4 pb-5">
                <li>a visitor to our website;</li>
                <li>a driving school; or</li>
                <li>
                  a customer who has ordered or requested the services that we
                  provide.
                </li>
              </ul>
            </p>
            <h3 className="font-bold">1.2 What is personal information?</h3>
            <p>
              Personal information includes:
              <ul className="list-disc ml-4 pb-5">
                <li>
                  certain information that we collect automatically when you
                  visit our website;
                </li>
                <li>
                  certain information collected on registration (see below);
                </li>
                <li>certain information collected on submission; and</li>
                <li>
                  optional information that you provide to us voluntarily (see
                  below);
                </li>
              </ul>
              but excludes:
              <ul className="list-disc ml-4 pb-5">
                <li>
                  information that has been made anonymous so that it does not
                  identify a specific person;
                </li>
                <li>
                  permanently de-identified information that does not relate or
                  cannot be traced back to you specifically;
                </li>
                <li>
                  non-personal statistical information collected and compiled by
                  us.
                </li>
              </ul>
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              2. Definitions and interpretation
            </h2>
            <h3 className="font-bold">2.1 Definitions. In the agreement:</h3>
            <p>
              terms means the terms, consisting of:
              <ul className="list-disc ml-4 pb-5">
                <li>these terms of use; and</li>
                <li>
                  any other relevant specific terms, policies, disclaimers,
                  rules, and notices agreed to between the parties, (including
                  any that may be applicable to a specific section or module of
                  the website)
                </li>
              </ul>
              we, us, or our means our organisation, the owner of the website
              and includes our officers, agents, employees, owners, co-branders,
              and associates where the terms limit or exclude our liability.{" "}
              <br></br>
              you or your means any visitor or user of this website, including
              any other person, website, business, or agent (including any
              virtual or robotic agent) associated with the visitor.
            </p>
            <h3 className="font-bold pt-5">Common Examples</h3>
            <p>
              Common examples of the types of personal information which we may
              collect and process include your:
              <ul className="list-disc ml-4 pb-5">
                <li>
                  identifying information {"-"} such as your name, date of
                  birth, or identification number of any kind;
                </li>
                <li>
                  contact information {"-"} such as your phone number or email
                  address;
                </li>
                <li>
                  address information {"-"} such as your physical or postal
                  address; or
                </li>
                <li>
                  demographic information {"-"} such as your gender or marital
                  status.
                </li>
              </ul>
            </p>
            <h3 className="font-bold">Sensitive personal information</h3>
            <p>
              Depending on the goods or services that you require, we may also
              collect sensitive personal information including your:
              <ul className="list-disc ml-4 pb-5">
                <li>
                  financial information {"-"} such as your bank account details;
                </li>
                <li>
                  sensitive demographic information {"-"} such as your race or
                  ethnicity;
                </li>
                <li>
                  medical information {"-"} such as information about your
                  physical or mental health;
                </li>
                <li>
                  sexual information {"-"} such as information about your sex
                  life or sexual orientation;
                </li>
                <li>
                  criminal information {"-"} such as information about your
                  commission or alleged commission of any offence or about any
                  related legal proceedings;
                </li>
                <li>
                  employment information {"-"} including your membership of a
                  trade union; and
                </li>
                <li>
                  beliefs {"-"} including your political or religious beliefs.
                </li>
              </ul>
            </p>
            <h3 className="text-3xl md:text-4xl text-black font-heading py-3">
              Acceptance
            </h3>
            <h3 className="font-bold">Acceptance required</h3>
            <p>
              You must accept all the terms of this policy when you order our
              goods or request our services. If you do not agree with anything
              in this policy, then you may not order our goods or request our
              services.
            </p>
            <h3 className="font-bold pt-5">Legal capacity</h3>
            <p>
              You may not access our website or order our goods or request our
              services if you are younger than 18 years old or do not have legal
              capacity to conclude legally binding contracts.
            </p>
            <h3 className="font-bold pt-5">Deemed acceptance</h3>
            <p>
              By accepting this policy, you are deemed to have read, understood,
              accepted, and agreed to be bound by all of its terms.
            </p>
            <h3 className="font-bold pt-5">Your obligations</h3>
            <p>
              You may only send us your own personal information or the
              information of another data subject where you have their
              permission to do so.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              2. How do we collect personal information?
            </h2>
            <h3 className="font-bold">
              2.1 On submission of an enquiry or registration
            </h3>
            <p>
              When you submit an enquiry or if you register on our website, you
              will no longer be anonymous to us.<br></br>This personal
              information may include:
              <ul className="list-disc ml-4 pb-5">
                <li>your name and surname;</li>
                <li>your email address;</li>
                <li>your telephone number;</li>
                <li>
                  your company name, company registration number, and VAT
                  number;
                </li>
                <li>your postal address or street address; and</li>
                <li>your username and password.</li>
              </ul>
              We will use this personal information to populate your account,
              provide additional services and information to you as we
              reasonably think appropriate, and for any other purposes set out
              in this policy.
            </p>
            <h3 className="font-bold">2.2 On order or request</h3>
            <p>
              When you order our goods or request our services from us, you will
              be asked to provide us with additional information on a voluntary
              basis (goods or services information).
            </p>
            <h3 className="font-bold pt-5">Events</h3>
            <p>
              You will provide us with personal information when you attend an
              event that we host.
            </p>
            <h3 className="font-bold pt-5">From browser</h3>
            <p>
              We automatically receive and record Internet usage information on
              our server logs from your browser, such as your Internet Protocol
              address (IP address), browsing habits, click patterns, version of
              software installed, system type, screen resolutions, colour
              capabilities, plug-ins, language settings, cookie preferences,
              search engine keywords, JavaScript enablement, the content and
              pages that you access on the website, and the dates and times that
              you visit the website, paths taken, and time spent on sites and
              pages within the website (usage information). Please note that
              other websites visited before entering our website might place
              personal information within your URL during a visit to it, and we
              have no control over such websites. Accordingly, a subsequent
              website that collects URL information may log some personal
              information.
            </p>
            <h3 className="font-bold pt-5">Cookies</h3>
            <p>
              For information on how we deal with cookies, please refer to our
              Cookies Policy.
            </p>
            <h3 className="font-bold pt-5">Web beacons</h3>
            <p>
              Our website may contain electronic image requests (called a
              single-pixel gif or web beacon request) that allow us to count
              page views and to access cookies. Any electronic image viewed as
              part of a web page (including an ad banner) can act as a web
              beacon. Our web beacons do not collect, gather, monitor or share
              any of your personal information. We merely use them to compile
              anonymous information about our website.
            </p>
            <h3 className="font-bold pt-5">Optional details</h3>
            <p>
              You may also provide additional information to us on a voluntary
              basis (optional information). This includes content or products
              that you decide to upload or download from our website or when you
              enter competitions, take advantage of promotions, respond to
              surveys, order certain additional goods or services, or otherwise
              use the optional features and functionality of our website.
            </p>
            <h3 className="font-bold pt-5">Recording calls</h3>
            <p>
              We may monitor and record any telephone calls that you make to us,
              unless you specifically request us not to.
            </p>
            <h3 className="font-bold pt-5">Who are our data subjects?</h3>
            <p>
              We process the personal information of the following categories of
              people:
            </p>
            <ul className="list-disc ml-4 pb-5">
              <li>customers or organisations,</li>
              <li>prospects or leads,</li>
              <li>employees,</li>
              <li>
                recruiters and medical practitioners providing services related
                to employees,
              </li>
              <li>contractors, vendors, or suppliers,</li>
              <li>children and their guardians,</li>
              <li>debtors and creditors,</li>
              <li>dealers, and</li>
              <li>directors and shareholders.</li>
            </ul>
            <h3 className="font-bold pt-5">Purpose for processing</h3>
            <p>
              We may use or process any goods or services information, or
              optional information that you provide to us for the purposes that
              you indicated when you agreed to provide it to us. Processing
              includes gathering your personal information, disclosing it, and
              combining it with other personal information. We generally collect
              and process your personal information for various purposes,
              including:
            </p>
            <ul className="list-disc ml-4 pb-5">
              <li>
                goods or services purposes {"-"} such as:
                <ul className="list-disc ml-4 pb-5">
                  <li>
                    collecting orders or requests for and providing our goods or
                    services,
                  </li>
                  <li>managing our contracts with various data subjects,</li>
                  <li>managing customer credit in general,</li>
                  <li>processing customer requests or complaints,</li>
                  <li>
                    keeping our data subject records and information up to date,
                  </li>
                  <li>
                    better understanding our data {"subject's"} needs, and
                  </li>
                  <li>providing support to our customers.</li>
                </ul>
              </li>
              <li>
                marketing purposes {"-"} such as:
                <ul className="list-disc ml-4 pb-5">
                  <li>marketing to customers,</li>
                  <li>marketing to prospects, and</li>
                  <li>running promotional competitions for business.</li>
                </ul>
              </li>
              <li>
                business purposes {"-"} such as:
                <ul className="list-disc ml-4 pb-5">
                  <li>managing employees in general,</li>
                  <li>internal audit,</li>
                  <li>accounting, and</li>
                  <li>
                    business planning and due diligence, and joint ventures,
                    disposals of business, or other proposed and actual
                    transactions.
                  </li>
                </ul>
              </li>
              <li>
                legal purposes {"-"} such as:
                <ul className="list-disc ml-4 pb-5">
                  <li>handling claims and enforcing debts, and</li>
                  <li>
                    complying with regulations, or pursuing good governance.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              We may use your usage information for the purposes described above
              and to:
            </p>
            <ul className="list-disc ml-4 pb-5">
              <li>
                remember your information so that you will not have to re-enter
                it during your visit or the next time you access the website;
              </li>
              <li>
                monitor website usage metrics such as total number of visitors
                and pages accessed; and
              </li>
              <li>
                track your entries, submissions, and status in any promotions or
                other activities in connection with your usage of the website.
              </li>
            </ul>
            <h3 className="font-bold pt-5">Consent to collection</h3>
            <p>We will obtain your consent to collect personal information:</p>
            <ul className="list-disc ml-4 pb-5">
              <li>in accordance with applicable law;</li>
              <li>
                when you provide us with any registration information or
                optional information.
              </li>
            </ul>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              3. Use
            </h2>
            <h3 className="font-bold">3.1 Our obligations</h3>
            <h3 className="font-bold pt-5">Messages and updates</h3>
            <p>
              We may send administrative messages and email updates to you about
              the website. In some cases, we may also send you primarily
              promotional messages. You can choose to opt-out of promotional
              messages. [OR] We may send administrative messages and email
              updates to you about the website. We may wish to provide you with
              information about new goods or services in which we think you may
              be interested. This means that in some cases, we may also send you
              primarily promotional messages. We will not send you promotional
              messages unless you have chosen to opt-into them. But, we may send
              you one message asking you to opt-into promotional messages
              without you having opted-into promotional messages.
            </p>
            <h3 className="font-bold pt-5">Targeted content</h3>
            <p>
              While you are logged into the website, we may display targeted
              adverts and other relevant information based on your personal
              information. In a completely automated process, computers process
              the personal information and match it to adverts or related
              information. We never share personal information with any
              advertiser, unless you specifically provide us with your consent
              to do so. Advertisers receive a record of the total number of
              impressions and clicks for each advert. They do not receive any
              personal information. If you click on an advert, we may send a
              referring URL to the {"advertiser's"} website identifying that a
              customer is visiting from the website. We do not send personal
              information to advertisers with the referring URL. Once you are on
              the {"advertiser's"} website however, the advertiser is able to
              collect your personal information.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              4. Reasons we Share Personal Information
            </h2>
            <h3 className="font-bold">4.1 Sharing</h3>
            <p>
              We may share your personal information with:
              <ul className="list-disc ml-4 pb-5">
                <li>
                  other divisions or companies within the group of companies to
                  which we belong so as to provide joint content and services
                  like registration, for transactions and customer support, to
                  help detect and prevent potentially illegal acts and
                  violations of our policies, and to guide decisions about our
                  products, services, and communications (they will only use
                  this information to send you marketing communications if you
                  have requested their goods or services);
                </li>
                <li>
                  an affiliate, in which case we will seek to require the
                  affiliates to honour this privacy policy;
                </li>
                <li>
                  our goods or services providers under contract who help
                  provide certain goods or services or help with parts of our
                  business operations, including fraud prevention, bill
                  collection, marketing, technology services (our contracts
                  dictate that these goods or services providers only use your
                  information in connection with the goods or services they
                  supply or services they perform for us and not for their own
                  benefit);
                </li>
                <li>
                  credit bureaus to report account information, as permitted by
                  law;
                </li>
                <li>
                  banking partners as required by credit card association rules
                  for inclusion on their list of terminated merchants (in the
                  event that you utilise the services to receive payments and
                  you meet their criteria); and
                </li>
                <li>
                  other third parties who provide us with relevant services
                  where appropriate.
                </li>
              </ul>
            </p>
            <h3 className="font-bold pt-5">Regulators</h3>
            <p>
              We may disclose your personal information as required by law or
              governmental audit.
            </p>
            <h3 className="font-bold pt-5">Law enforcement</h3>
            <p>We may disclose personal information if required:</p>
            <ul className="list-disc ml-4 pb-5">
              <li>by a subpoena or court order;</li>
              <li>to comply with any law;</li>
              <li>
                to protect the safety of any individual or the general public;
                and
              </li>
              <li>to prevent violation of our customer relationship terms.</li>
            </ul>
            <p className="italic pt-5 bg-gray-200 p-5">
              We may disclose personal information to third parties if required
              for legal reasons.
            </p>
            <h3 className="font-bold pt-5">No selling</h3>
            <p>
              We will not sell personal information. No personal information
              will be disclosed to anyone except as provided in this privacy
              policy.
            </p>
            <h3 className="font-bold pt-5">Marketing purposes</h3>
            <p>
              We may disclose aggregate statistics (information about the
              customer population in general terms) about the personal
              information to advertisers or business partners.
            </p>
            <h3 className="font-bold pt-5">Employees</h3>
            <p>
              We may need to disclose personal information to our employees that
              require the personal information to do their jobs. These include
              our responsible management, human resources, accounting, audit,
              compliance, information technology, or other personnel.
            </p>
            <h3 className="font-bold pt-5">Change of ownership</h3>
            <p>
              If we undergo a change in ownership, or a merger with, acquisition
              by, or sale of assets to, another entity, we may assign our rights
              to the personal information we process to a successor, purchaser,
              or separate entity. We will disclose the transfer on the website.
              If you are concerned about your personal information migrating to
              a new owner, you may request us to delete your personal
              information.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              5. Security
            </h2>
            <p>
              There is no such thing as {"'perfect security'"}. We have to
              compromise between increased levels of security and the
              convenience to you in transacting with us.
            </p>
            <h3 className="font-bold">5.1 Our security responsibilities</h3>
            <p>
              We take the security of personal information very seriously and
              always do our best to comply with applicable data protection laws
              by maintaining reasonable measures to protect personal information
              from loss, misuse, and unauthorised access, disclosure,
              alteration, and destruction.<br></br>Our hosting company will host
              our website in a secure server environment that uses a firewall
              and other advanced security measures to prevent interference or
              access from outside intruders. We authorise access to personal
              information only for those employees who require it to fulfil
              their job responsibilities. We implement disaster recover
              procedures where appropriate.
            </p>
            <h3 className="pt-5 font-bold">5.2 Our security disclaimers</h3>
            <p>Please note the following:</p>
            <ul className="list-disc ml-4 pb-5">
              <li>
                The third parties whose systems we link to are responsible for
                the security of information while it is collected by, stored on,
                or passing through the systems under their control.
              </li>
              <li>
                We will use all reasonable endeavours to ensure that our website
                and your information is not compromised. However, we cannot
                guarantee that no harmful code will enter our website (for
                example viruses, bugs, trojan horses, spyware or adware). You
                should be aware of the risks associated with using websites
                (addressed below).
              </li>
              <li>
                If you experience a problem or loss that is caused by
                information you provided to us, your computer being compromised
                in some way or by something beyond our control, we cannot take
                responsibility for causing the problem. We will, however, do our
                best to help you if we can.
              </li>
            </ul>
            <h3 className="pt-5 font-bold">5.3 Phishing</h3>
            <p>
              {" "}
              You must only log in to your account from a page that begins with
              https://nubee.co.za/ or https://app.nubee.co.za/
            </p>
            <p className="italic pt-5 bg-gray-200 p-5">
              Our website is hosted on a secure server and uses security
              measures to prevent interference by intruders.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              6. Accuracy and retention
            </h2>
            <h3 className="font-bold">6.1 Accurate</h3>
            <p>
              We will try to keep the personal information we collect as
              accurate, current, complete, confidential and reliable for the
              purposes defined in this policy. From time to time we may request
              you to update your personal information on the website. You are
              able to review or update any personal information that we hold on
              you by accessing your account online, emailing us, or phoning us.
              Please note that in order to better protect you and safeguard your
              personal information, we take steps to verify your identity before
              granting you access to your account or making any corrections to
              your personal information.
            </p>
            <p className="italic pt-5 bg-gray-200 p-5">
              Please keep your personal information accurate and up to date by
              accessing your account online, emailing us, or by phoning us.
            </p>
            <h3 className="pt-5 font-bold">6.2 Retention</h3>
            <p>
              We will only retain your personal information for as long as it is
              necessary to fulfil the purposes explicitly set out in this
              policy, unless:
            </p>
            <ul className="list-disc ml-4 pb-5">
              <li>
                retention of the record is required or authorised by law; or
              </li>
              <li>you have consented to the retention of the record.</li>
            </ul>
            <p>
              During the period of retention, we will continue to abide by our
              non-disclosure obligations and will not share or sell your
              personal information. We may retain your personal information in
              physical or electronic records at our discretion.
            </p>
            <p className="italic pt-5 bg-gray-200 p-5">
              We will only retain your personal information for as long as is
              necessary.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              7. Transfer to Another Country
            </h2>
            <p>
              We send personal information outside of South Africa to various
              countries. We will only transfer data to other countries who have
              similar privacy laws to South {"Africa's"} that provide an
              adequate level of protection, or to recipients who can guarantee
              the protection of personal information to the same standard we
              must protect it.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              8. Your Rights
            </h2>
            <h3 className="font-bold">
              8.1 Request what information we hold on you
            </h3>
            <p>
              You may request access to your personal information to receive a
              copy of the personal information that we hold on you.
            </p>
            <h3 className="font-bold">8.2 Updating or removing</h3>
            <p>
              You may choose to correct or update the personal information you
              have submitted to us, by clicking the relevant menu in any of the
              pages on our website or contacting us by phone or email.
            </p>
            <h3 className="font-bold">
              8.3 Withdrawal of consent or objection to processing
            </h3>
            <p>
              You may withdraw your consent where we are relying on consent as a
              lawful justification to process. You may also object to our
              processing where we are relying on another lawful justification
              for processing. Please note that if you do so, we might not be
              able to provide services to you.
            </p>
            <p className="pt-5">
              We may need to request additional information from you to verify
              your identity for you to access these rights. This is to ensure
              that your personal information is not disclosed to an unauthorised
              person.
            </p>
            <p className="italic pt-5 bg-gray-200 p-5">
              You may choose to update or remove the personal information you
              have submitted to us.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              9. Changes
            </h2>
            <p>
              We may change the terms of this policy at any time by updating
              this web page. We will notify you of any changes by placing a
              notice in a prominent place on the website or by sending you an
              email detailing the changes that we have made and indicating the
              date that they were last updated. If you do not agree with the
              changes, then you must stop using the website and our goods or
              services. If you continue to use the website or our goods or
              services following notification of a change to the terms, the
              changed terms will apply to you and you will be deemed to have
              accepted those updated terms.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              10. Limitation
            </h2>
            <p>
              We are not responsible for, give no warranties, nor make any
              representations in respect of the privacy policies or practices of
              linked or any third party websites.
            </p>
          </div>
          <div className="md:max-w-3xl pt-10">
            <h2 className="text-3xl md:text-4xl text-black font-heading py-3">
              11. Enquiries
            </h2>
            <p>
              If you have any questions or concerns arising from this privacy
              policy or the way in which we handle personal information, please
              contact us via email: privacy@nubee.co.za
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPage;